
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html, body, #root {
  height: 100%;
  margin: 0;
}
.css-1wvake5{
  border-right-width: 0px !important;
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  background-color:#ffffff;
  padding: 15px 0px 15px 30px;
  box-shadow: 0px 1px 1px rgb(221 234 247);
  width: 100%;
  margin: 0 auto;
 
}
.profile-button{
  background-color: #f8faff !important;
  margin-right: 30px;
  border: none!important;
  color: black!important;

}
.sidebar-main{
  background-color:#3e53a0;
  position: relative !important;
  border-right-width: 1px;
  border-right-style: solid;
  border-color: #efefef;
  -webkit-transition: width, left, right, 300ms;
  transition: width, left, right, 300ms;
  width: 250px;
  min-width: 250px;
  height: 181vh;
}

.logo{
  
  color:white;
  display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    align-items: center;
   height:35px;
   width:auto;
   margin-top: 10px;
   margin-bottom: 8px;
}

.css-dip3t8 {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
   background-color:#fff!important
}
.logout{
  width: 25px;
  margin: 5px;
}
/* General link styles */
a {
  color: black !important;;/* Change the link color */
  text-decoration: none!important; /* Remove the underline */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px; /* Change the font size */
  padding: 5px 10px; /* Add padding */
  margin: 5px; /* Add margin */
  transition: color 0.3s ease, background-color 0.3s ease; /* Add transition for smooth effect */
}

.background{
 /* Apply background image to the entire body */
 width: 100%;
  height: 100vh;
  /* background: linear-gradient(135deg, #fac4b3, #9c7dc0); */
  /* background: linear-gradient(135deg, #e3e2f3, #2c1d3c); */
  /* background: linear-gradient(135deg,  rgba(42,74,125,1) 0%,rgba(99,124,170,1) 50%,rgba(88,117,175,1) 100%); */
  background-image: url(.././public/background.png);
  background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.main{
  display: flex;
  width: 100%;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  height: 100vh; /* Full viewport height */
}
.cards {
  background-color: white; /* Card background color */
  padding: 20px; /* Padding inside the card */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for better visibility */
  max-width: 300px; /* Optional: Set a max-width for the card */
  text-align: center; /* Optional: Center text inside the card */
}

.form-label{
  color: #00264d;
  
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin:0!important
}
.form-control {

  background-color: #F1F4F9
  !important; 
  color: #A6A6A6!important;
  height: 48px!important;
  border-radius: 10px!important;
  font-family: "Poppins", sans-serif!important;
  padding-left:20px!important

}
.form-control-cust {

  padding:20px!important;

}
.muted{
  color: #00264d!important;
}
.form-text{
  color: #00264d!important;
}
.custom-input::placeholder {
  color: rgb(210, 206, 206)!important; /* Placeholder text color */
  font-size: 17px!important;
}
.sign_up{
  font-size: 16px !important;
  font-weight: 600;
}
.profile{
width: 200px;
margin: 20px 0px;
}
.flex{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  
}

.signup-name{
  padding:0px 30px;
}
.signup-img{
  position: absolute;
  bottom: 267px;
  left: 206px;
}
.signup-heading{
  text-align: center;
    font-size: 35px;
    margin-bottom: 20px;
    color: #00264d;
    font-family: "Poppins", sans-serif;
    font-weight: 550;
}


.main {
  position: relative;
  width: 540px;
  margin: 0 auto; /* Center the container horizontally */
}

.myform {
  width: 540px; /* Adjust to fit the container */

  border: 1px solid #b79a9a;
  padding: 50px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 20px;
 
  position: relative;
  z-index: 2; /* Ensure form is above the button */
}

.login-button {
  margin-top: 20px;
  background-color: #4880FF;
  border: none;
  /* position: absolute;
  top: 651px;
  left: 50%; */
  /* transform: translateX(-50%); */
  border-radius: 15px;
  border: none;
  color:white;
  z-index: 1;
  padding: 11px 156px;
  font-size: 17PX;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
  font-weight: 500;
}
.signup-button{
 margin-top: 20px;
    background-color:#4880FF;
    border: none;
    /* position: absolute;
    bottom: 125px;
    left: 50%;
    transform: translateX(-50%); */
    border-radius: 15px;
    border: none;
    color:white;
    z-index: 1;
    padding: 11px 156px;
    font-size: 17PX;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
    font-weight: 500;

}
.toast-container {
  top: 50% !important;
  
  right: 250 !important;
}
.page-main{
  
  height:93.3vh;
  /* height:100vh; */
  padding:40px;
}
.page-inner-main{

  /* background-color: #fdfdfe; */
  padding:40px;
  display: flex;
  justify-content: center;
}
.email-div{
      width:100%;
    background-color: #ffffff;
    border: 1px solid #c7c6c6;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}
.email-heading{
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.white{
  background-color: transparent!important;
  color: black!important;
}
.btn-div{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.email-btn{
  /* width:30%;
  padding: 11px 50px;
  border-radius: 10px;
  border: 1px solid #c7c6c6;
  background-color: #b6c5ec; */
  width: 250px;
  padding: 11px 50px;
  border-radius: 10px;
  border: none;
  background-color: #198ef9;
  font-weight: 600;
  color: #fff;
  font-size: 15px;
  letter-spacing: 1px;

}
.email-btn1{


  border:none;
  background-color: transparent;
}
.email-btn2{

  padding: 11px 50px;
  border-radius: 2px;
  border: 1px solid #c7c6c6;
  background-color: #eccbb6;
}
.margin{
  margin-bottom: 10px !important;
}

.availability {
  margin-bottom: 10px;
  font-size: 17px;
  align-items: center;
    background: transparent;
    padding: 20px 20px 15px;
    border-radius: 15px;
    border: 1px solid #ededed;

}

.availability label {
  margin-right: 10px;
}
.checkbox-input{
  margin: 10px;
}
.slot-main{
  display: flex;
  flex-direction: column
  ;
}
.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center}

  .control{
   

     
    height: 50px !important;
    border-radius: 15px !important;
    font-family: "Poppins", sans-serif !important;
    padding: 0px 15px !important;
    
  }
  .timezone-label{
  margin-right: 20px;
  font-size: 20px;
  margin-bottom: 0px;
  }
  .timezone-outer{
   margin: 20px 0px 50px;
  }
/* CSS */
/* Base styles for the custom checkbox */
input[type="checkbox"].custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  border: 2px solid #555; /* Border color */
  border-radius: 50%; /* Optional: Make it square or rounded */
  background-color:white;  /* Background color */
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

/* Style the checkbox when checked */
input[type="checkbox"].custom-checkbox:checked {
  background-color: #28a745; /* Green background when checked */
  border: 2px solid #28a745;
  border-radius: 50%; /* Border color when checked */
}

/* Add a green tick mark when the checkbox is checked */
input[type="checkbox"].custom-checkbox:checked::after {
  content: '\2713'; /* Unicode checkmark character */
  display: block;
  font-size: 16px; /* Adjust font size as needed */
  color: #fff; /* Color of the tick */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the checkmark */
  border-radius: 50%;
}

.check{
  margin:0px 20px 0px 0px
}
.time-select {
  margin: 0px 0px 0px 20px;
  padding: 5px;
  border-radius: 5px;
  border-color: #b6c5ec;
  font-size: 14px;
  padding:8px;
}
.timezone-inner{
  align-items: center;
}
.add_icon{
  width:20px;
}

.cross {
  background: transparent;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: bold;
  color: #fff;
  border: transparent;
}

.slot-main label {
  position: relative;
}

.slot-main label span {
  position: absolute;
  top: -11px;
  font-size: 12px;
  background: #fff;
  left: 25px;
  line-height: normal;
  padding: 0px 4px;
  border-radius: 2px;
}
.page_m{
  margin: 8px 11px;

}
text.txt_booking {
  font-size: 22px;
    text-transform: capitalize;
    font-weight: 600;
}
table.table_data {
  text-align: center;
  margin-top:40px;
  width:100%
}
.bg_table{
  text-align: center;
  font-size: 16px;
    font-family: sans-serif;
    text-transform: uppercase;
}
.search_bar{
  /* margin-right: 45px; */
}
.table_row{
  margin-bottom: 15px;
  border:1px solid #ebeaea;
}
.table_head {
  padding: 20px 35px;
}
.table>:not(caption)>*>*{
  padding: 0!important;
}

.table_row_data td {
  text-align: center !important;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid #eef2ff;
  padding: 15px 0px!important;
}

.btn_previus{
  background-color: #b6c5ec;
  color: #000;
  padding: 7px 15px;
  border: none;
  border-radius: 8px;
}

  button:disabled {
    background-color: #198ef9;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 0px 40px;
}
.ps-menu-button:hover {
  background: #198ef9!important;
  border-radius: 9px ;
  margin: 12px 20px;
}
.active-menu .ps-menu-button {
  background: #198ef9!important;
  margin: 10px 20px;
  border-radius: 8px;
  
}
.header-user{
  display: flex;
    flex-direction: column;
    font-size: 22px;
    font-weight: 600;
    color:rgb(0, 0, 0)

}
.header-user a {
  margin: 0;
  padding: 0;
  font-size: 12px;
  margin-top: 5px;
  color:rgb(0, 0, 0)!important
}
.ps-menu-root {
  padding: 12px 0px;
}



@media screen and (max-width: 600px) {
  .email-div {
   
    padding: 20px;
  }
  .control {
     padding: 0px 5px !important; 
    width: 100%;
    font-size: 14px;
}
.timezone-label {
  margin-right: 5px;
  font-size: 15px;
}
.availability {
  font-size: 15px;
  padding: 12px 0px 10px 0px;

  
}
.page-main {
  padding: 0px;
}
.page-inner-main {
  /* background-color: #fdfdfe; */
  padding: 10px;
}
.css-1wvake5 {
  
  width: 80px !important;
  min-width: 80px !important;
}

.logo {
  /* padding: 10px 6px 10px 2px ;
  font-size: 15px;
  color: white; */


}
/* .email-btn {
  width: 250px;
  padding: 11px 50px;
  border-radius: 10px;
  border: none;
  background-color: #3e53a0;
  font-weight: 600;
  color: #fff;
  font-size: 15px;
  letter-spacing: 1px;
} */
}

.sidebar-img svg{
  width:18px;
  margin-right: 12px;
  height:18px;
}
.tooltip-text{
  font-size: 15px;
}

.tooltip-container{
display: flex;
}
.ps-menu-button:hover {
  background: #198ef9!important;
  border-radius: 9px ;
  margin: 12px 20px;
}
.ps-menu-button:hover a {
  color: #ffffff !important;
  fill:#ffffff;
  font-weight: 700;
}

.ps-menu-button a {
  color: #000!important;
  fill:#000;
  font-weight: 700;
}
.active-menu .ps-menu-button {
  background: #198ef9!important;
  margin: 10px 20px;
  border-radius: 8px;
  
}
.active-menu .ps-menu-button a {
  color: #ffffff !important;
  fill:#ffffff;
  font-weight: 700;
}
.header-user{
  display: flex;
    flex-direction: column;
    font-size: 22px;
    font-weight: 600;
    color:rgb(0, 0, 0)

}
.header-user a {
  margin: 0;
  padding: 0;
  font-size: 12px;
  margin-top: 5px;
  color: rgb(53 142 249) !important;
    font-size: 12px !important;
}

.header button {
  border: none;
  background: #358ef9;
  ;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 25px;
}

.logout svg {
  width: 20px;
  fill: #fff;
}

.slot {
  margin: 12px 0px;
}
/* Table Css  */
.bg_table .table_row th {
  color: #3e53a0;
  background-color: #eaefff;
  font-size: 12px;
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 1px solid #e9ecef;
  font-weight: 900;
}
.time_zone{
  
    border: 2px solid #000;
    border-radius: 17px;

}
a{
  font-size: 14px !important;
}


.ps-menu-button{
  padding-right: 24px !important;
  padding-left: 24px !important;
  margin: 10px 20px!important;
  border-radius: 9px;
}
.margin1{
 margin-right: 10px;
 color: white;
 background-color:#198ef9;
 font-weight: 600;
}


/* custom   preview*/
/* tyyyyyyyyiodjoigodfofjifohofihj;fihjoptjohpdfjdrsopdopsdhd;jpohpodjpfjfpd */



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* My customize Css */
.outer{
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  justify-content: center;
}
.inner1{
  display: flex;
    justify-content: center;
}
.inner2{
  max-width: 680px;
  display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
    background-color: var(--color-bg-white-l-1, rgba(255, 255, 255, 1));
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */


}
.mainss{
  /* border:1px solid hsl(240deg 13.54% 88.15%); */
  padding:0px;
  margin: 0.67em 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
 
}
.main-padding{
  padding:40px;
}
.head{
  /* max-width: 320px; */
  margin: 0 auto 40px;
  text-align: center;
}
.head1{
  color: #000;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 14px;
    font-family: "Source Sans 3", sans-serif
}
.head2{
  padding: 0px 20px;
  color: var(--text-color-level2, rgba(26, 26, 26, 0.61));
  font-size: 16px;
  font-family: "Source Sans 3", sans-serif
}
.discussion{
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 0 auto;
  flex-direction: column;
}
.marker{
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border: solid 2px #fff;
  border-radius: 50%;
  background-clip: padding-box;
  background-color: rgb(130, 71, 245); 
}

.context{
  flex: 1 1 auto;
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.2;

}
.arrow{
  position: relative;
    right: -8px;
    border: 8px solid transparent;
    border-left-color: var(--text-color, rgb(26, 26, 26))
}
.event-redirect{
  display: flex;
    align-items: center;
    height: 40px;
   
}
.discussion-hidden{
  border: 1px solid #0000;
  border-top-color: #1a1a1a1a;
  border-top: 1px solid var(--text-color-level3, #1a1a1a1a);
  color:white;
  color: var(--text-color,white);
  display: block;
  flex: 100% 1;
  margin: auto;
  min-height: 0;
  padding: 20px 10px;
  text-decoration: none;
  transition: border-color .1s ease;
  background: #93aff9;
  border-radius: 5px;
  padding: 20px 40px;transition: .35s ease-in-out
}
.discussion-hidden:hover {
  background: #82a0f2;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.step2-main{
  border-bottom: 1px solid var(--text-color-level3, rgba(26, 26, 26, 0.1));
  position: relative;
  padding-bottom: 10px;
  background: #93aff9;
  border-radius: 10px 10px 0px 0px;

}

.step2-head{
  display: flex;
  flex-direction: column;
  
  height: 100%;
}
.wrapper-main{
  flex: 1 1 0;
}
.simplebar1{
  overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
    margin: 0px;
}
.simplebar-horzontal{
  left: 0;
  height: 11px;
  visibility: hidden;
}
.simplebar-vertical{
  top: 0;
  width: 11px;
  visibility: hidden;
}
/* .step2-top-div{
  padding-top: 25px;
} */
 .step2-hidden{
   background: transparent;
    
    border: none;
   
} 
.step2-previous{
  position: relative;
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    font-family: icomoon;
    line-height: 1;
    text-transform: none;
    left:20px;
  bottom:-20px;

























    -webkit-font-smoothing: antialiased;
}
.step2-previous-img{
  height:30px
}
.step2-sec-div{
  text-align: center;
  margin-bottom: 24px; 
  color:white;
}
.step2-sec-div2{
  padding-right: 45px;
  padding-left: 45px;
  color:white;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}
.step2-sec-div-h1{
  padding-right: 45px;
    padding-left: 45px;
    font-size: 26px;
    margin: 0;
    font-weight: 600;
    
    line-height: 32px;

}


.step2-sec-div-h1-cus{
  padding-right: 45px;
    padding-left: 45px;
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    
    line-height: 32px;

}

.step2-sec-inner-div{
  margin-right: auto;
    margin-left: auto;
    max-width: 340px;
    padding: 0 23px;
    margin-bottom: 20px;
}

.step2-sec-inner-div2{
  color: var(--text-color-level2, rgba(26, 26, 26, 0.61));
    font-weight: 700;
    text-align: center;
}

.step2-sec-inner-div3{
  margin-bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  color:white;
  justify-content: center;
}
.step2-sec-inner-div3-timer{
  align-self: flex-start;
  width: 20px;
  height: 20px ;
  margin-right: 10px;

}
.step2-sec-inner-div3-span{
  width: 20px;
  height: 20px;
  display: inline-block;
    vertical-align: top;  
    margin-left: 5px;
    margin-right: 5px;
}
 .step2-sec-main{
  flex: 1 0 auto;
 }
 .spotpicker{
  /* min-height: 600px; */
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  padding: 0px;

 }

 .spotpicker-inner{
  max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    border: 1px solid #93aff9;
    border-radius: 16px;
    padding: 30px 0px 50px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

 }
 .spotpicker-date{
  max-width: 100%;
 }
.spotpicker-label{
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}
.react-datepicker{
  /* width:100%;
  
  background-color: transparent!important;
  margin-top: 30px; */
  /* max-width: 340px; */
  width:100%;
  border:none!important;
  
  
}
.react-datepicker__month-container{
  width:100%
}

.react-datepicker__day-name{
  margin:12px!important;

}
.react-datepicker__day{
  margin:12px!important;
  font-size: 16px!important;
}
.react-datepicker__header{
background-color:transparent!important;
text-align: center;
   
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}
/* #8bc0e9 */


.react-datepicker__navigation--previous{
  left:80px!important;
  background-color:#6e6e6e!important ;
  border-radius: 50%;
 
}

.react-datepicker__navigation--next {
  right:80px!important;
  background-color:#6e6e6e!important ;
  border-radius: 50%;
 

}
.timezone-outer{
  max-width: 320px;
    margin-right: auto;
    margin-left: auto;
    position: relative;

}
.timezone-inner{
  position: relative;
  
}
.align-left{
  text-align: left;
}
.align-center{
  text-align: -webkit-center;
}
.timezone-label{
  color: currentcolor;
  font-weight: 700;
  font-size: 16px;
 
}
.timezone-inner2 {
  width:  -webkit-fill-available ;
  padding: 10px 17px 11px 11px;
  border-radius: 11px;
  font-size: 15px;
  text-align: center;
}
.timezone-inner2-button{
  display: flex;
  border:none;
  align-items: center;
  background-color:transparent;

  font-size: larger;
  width:100%
  
}
.timezone-inner2-button:hover {
  background-color: #f1f6ff; 
  border-radius: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.timezone-inner2-span{
  position: relative;
    top: -1px;
    flex-shrink: 0;
    margin-right: 12px;

}
.timezone-inner2-div1{
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.timezone-inner2-div2{
  flex-shrink: 0;
    margin-right: 4px;
    margin-left: 3px;

}
.timezone-inner2-span2{
  flex-shrink: 0;
    font-size: 8px;
    position: relative;
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    font-family: icomoon;
    line-height: 1;
    text-transform: none;
    content: "\e95f";
    -webkit-font-smoothing: antialiased;
    flex-shrink: 0;
    font-size: 8px;

}
.world-img{
  height:31px;
  vertical-align: middle;
}
/* .css-13cymwt-control{
  border-radius: 19px!important;
} */
.step3-sec-div2{
  margin-top: 0;
    
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
}
.step3-sec-div-h1{
  
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
}
.step3-sec-main-div{
  flex: 1 0 auto;
  height: 600px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.step3-sec-outer-div{
  padding-top: 15px;
  min-height: 600px;
  display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    padding: 25px 0 0;
}
.step3-sec-outer-div2{
  max-width: 100%;
  margin-right: auto;
    margin-left: auto;
    width: 100%;
}
.step3-sec-inner{
  padding-right: 15px;
    padding-left: 15px;
}
.step3-sec-inner-h2{
  margin-bottom: 5px;
  font-weight: 700;
  text-align: center;
}
.step3-duration-outer-div{
  padding-bottom: 15px;

}
.step3-duration-outer-div1{
  margin: 12px 0;
    font-size: 14px;
    text-align: center;

}
.step3-duration-inner-div{
overflow: hidden;
    margin-top: 8px;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.step3-duration-inner-div-main{
  margin: 10px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    
}
.step3-duration-inner-div-main-button{
  width: 100%;
   
    /* border: 1px solid  rgba(0, 105, 255, 0.5);
    color: var(--primary-color, rgb(0, 105, 255)); */
    border:none;
    background: transparent;
    

}
.step3-duration-inner-div-main-button-div{
  width: 100%;
  position: relative;
    margin-right: auto;
    margin-left: auto;
  
 
}
.step3-duration-inner-div-main-button-div2{
  width: 100%;
  
  border: 1px solid  rgba(0, 105, 255, 0.5);
  color: var(--primary-color, rgb(0, 105, 255));
  position: relative;
 
  
  border-radius: 4px;
  font-weight: 700;
  transition: all .3s ease;
  transition-property: width, transform;
  margin-bottom:10px;
  
  
  background-color: transparent;
  font-size: large;
  
  
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  line-height: 10px; 
}
.step3-duration-inner-div-main-button-div2-text{
  
    text-align: center;
    align-content: center;
    padding:20px;
}
.else-div{
  display: flex;
 flex-direction: row;
 gap:10px;
}

.time-zone{
  font-size: 17px;
  border: 1px solid #e5dcdc;
  border-radius: 16px;
   
   width: auto;
  padding: 3px;

}
.step3-duration-inner-div-main-button1-div2{
  border-color: transparent;
  background-color: rgb(0 0 0 / 60%);
  color: var(--text-color-secondary-4, rgba(255, 255, 255, 1));
  height: 52px;
  position: relative;
  border-radius: 4px;
  font-weight: 700;
  transition: all .3s ease;
  transition-property: width, transform;
  margin-bottom:10px;
  font-size: large;
   -webkit-text-decoration: none;
  text-decoration: none;
  cursor: none;
  line-height: 10px; 
}

.step3-duration-inner-div-main-button2-div2{
  border-color: transparent;
  background-color: var(--primary-color, rgb(0, 105, 255));
    color: var(--primary-text-color, #ffffff);
  height: 52px;
  position: relative;
  border-radius: 4px;
  font-weight: 700;
  transition: all .3s ease;
  transition-property: width, transform;
  margin-bottom:10px;
  font-size: large;
   -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  line-height: 10px; 
}

.step4-sec-outer-div{
 
    padding: 0 15px;
    margin-bottom: 20px;
    margin-top: 24px;
}
.step4-sec-outer-div1{
  color: var(--text-color-level2, rgba(26, 26, 26, 0.61));
    font-weight: 700;
}
.step4-main-sec-div{
  flex: 1 0 auto;
}
.step4-main-sec-div1{
max-width: 860px;
    margin-right: auto;
    margin-left: auto;
    padding: 40px;
}
.step4-main-sec-inner-div{
  margin-right: auto;
    margin-left: auto;
}
.step4-main-sec-inner-h2{
  margin-top: 0;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.2;
}
.step4-form-outer{
  margin-bottom: 0;
    --color-bg-white-l-1: #ffffff;
}
.step4-fieldset1{
  margin: 0;
    padding: 0;
    border: none;
}
.step4-fieldset1-div1{
  margin-bottom: 20px;
}
.step4-fieldset1-div2{
  display: flex;
  gap: 4px;
  align-items: center;
  min-height: 22px;
  margin-bottom: 8px;
  color: var(--text-color, rgb(26, 26, 26));
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
.step4-fieldset1-div2-label{
  display: flex;
    gap: 4px;
}
.step4-fieldset1-div2-input{
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 46px;
  padding: 10px 14px;
  border: 1px solid var(--color-grey-2, #B2B2B2);
  border-radius: 8px;
  background: var(--color-bg-white-l-1, rgba(255, 255, 255, 1));
  color: #1a1a1a;
  font-size: 16px;
  line-height: 1.5;
  overflow-wrap: normal;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.step4-add-guest-outer{
  margin-top: -10px;
    margin-bottom: 20px;
}
.step4-add-guest-button{

  border-color: var(--primary-color, rgb(0, 105, 255));
  color: var(--primary-color, rgb(0, 105, 255));
  width: 100%;
  min-height: 32px;
    padding: 9px 12px;
    border-radius: 16px;
    background: transparent;

}
.step4-add-guest-button:hover{
  background-color: #f5f8fa;
}
.step4-location-outer{
  margin-bottom: 20px;
}

.step4-location-choices{
  margin: 0;
    padding: 0;
    border: none;
}
.legend{
  display: flex;
  gap: 4px;
  align-items: center;
  min-height: 22px;
  margin-bottom: 8px;
  color: var(--text-color, rgb(26, 26, 26));
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
.legend-label{
  display: flex;
    gap: 4px;
    cursor: default;
}
.location-outer-div{
  min-height: 28px;
    margin-bottom: 7px;
}
.location-outer-div-label{
  display: inline-flex;
    cursor: pointer;
}
.location-outer-div2{
  margin-right: 20px;
  display: inline-block;
  flex-shrink: 0;
  cursor: pointer;
}
.location-outer-div2-input{
  position: absolute;
  width: 20px;
    height: 20px;
  
}
.radio-div{
  
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
}
.step4-location-full-div{
  display: flex;
}
.step4-location-full-div1{
  width: 30px;
}
.google-conference{
  position: relative;
    width: 24px;
    height: 24px;
}
.notes-outer-div{
  margin: 0;
    padding: 0;
    border: none;
}
.notes-outer-div1{
  
  margin-bottom: 20px;
}
.notes-inner-div{
  display: flex;
    gap: 4px;
    align-items: center;
    min-height: 22px;
    margin-bottom: 8px;
    color: var(--text-color, rgb(26, 26, 26));
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}
.notes-inner-div-label{
  display: flex;
    gap: 4px;
}

.notes-inner-div2-textarea{
  resize: vertical;
  display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 46px;
    padding: 10px 14px;
    border: 1px solid var(--color-grey-2, #B2B2B2);
    border-radius: 8px;
    background: var(--color-bg-white-l-1, rgba(255, 255, 255, 1));
    color: #1a1a1a;
    font-size: 16px;
    line-height: 1.5;
    overflow-wrap: normal;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

}
.button-submit{
  border-color: var(--primary-color, rgb(0, 105, 255));
    background-color: var(--primary-color, rgb(0, 105, 255));
    color: var(--text-color-secondary-4, rgba(255, 255, 255, 1));
    font-weight: 700;
    line-height: 22px;
    width: 100%;
    font-size: 16px;
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 44px;
    padding: 8px 16px;
   
    border-radius: 40px;
    margin: 0;
    padding: 0;
   
    
    
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: pointer;
}
.button-submit-span{
  gap: 8px;
  display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.step4-sec-div-h1{
  padding-right: 15px;
    padding-left: 45px;
    font-size: 26px;
    margin: 0;
    font-weight: 600;
    
    line-height: 32px;

}
.guest-email{
  margin-bottom: 20px;
}
.guest-email-div{
  margin-bottom: 10px;
}
.guest-email-div2{
  display: flex;
  gap: 4px;
  align-items: center;
  min-height: 22px;
  margin-bottom: 8px;
  color: var(--text-color, rgb(26, 26, 26));
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
.guest-email-div2-label{
  display: flex;
  gap: 4px;
}
.guest-email-div3-input{
      width: 100%;
    min-height: 71px;
    border-radius: 13px;
    /* border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133)); */
    border: 1px solid var(--color-grey-2, #B2B2B2);
    font-size: 16px;
}
.success-main{
  text-align: center;
  color:white;
  
}
.success-main-inner{
  text-align: center;
  margin-bottom: 24px;
  color:white;
}
.success-h1{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 1.2;
  color:white;
}
.success-div{
  width: 16px;
  margin-right: 14px;
  padding-top: 2px;
  color:white;
}

.success-span{
  color: var(--color-success, #038164);
  width: 20px;
  height: 20px;
  display: inline-block;
    vertical-align: top;
}
.inner3{
  flex: 1 0 auto;
}
.inner4{
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 30px;
    padding-bottom: 30px;
}
.success-top-sec-main{
  padding-right: 15px;
  
  width: 100%;
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 15px;

}
.success-top-sec-main-div{
  text-align: center;
  margin-bottom: 25px;
}
.success-2nd-main{
  padding: 20px;
  border: 1px solid var(--color-grey-3, #CCCCCC);
  border-radius: 8px;
  background-color: #93aff9;
  color: white;

    box-shadow: 0 7px 29px 0 #64646f33;
    margin: .67em 0;
  

}
.success-2nd-main-div{
  
  font-weight: 700;
}
.success-2nd-div1{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  text-align: left;
  justify-content: center;
}
.success-2nd-div1-h1{
  color: white;
  font-size: 20px;
  line-height: 1.2;
}

.success-info-span1{
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: inline-block;
    vertical-align: top;
    flex-shrink: 0;
    text-align: left;

}
.success-info-span2{
  
  font-weight: 700;
  color: white;
}

.success-time-info-div1{
  display: flex;
  align-items: center;
}

.success-time-info-div2{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}
.success-time-info-div3{
  width: 100%;
  display: flex;
 justify-content: center;

}
.step3-sec-main-div::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #fff;
}

.step3-sec-main-div::-webkit-scrollbar
{
	width: 6px;
	background-color: #fff;
}

.step3-sec-main-div::-webkit-scrollbar-thumb
{
	background-color: #93aff9;
}

.react-datepicker__navigation {
  background-color: #93affa!important;
}
.react-datepicker__navigation-icon::before{
  border-color: white!important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
  border-radius: 50%!important;
  background-color: #93affa!important;
  color:white!important
}

.not-found {
  text-align: center;
  margin-top: 50px;
}

.not-found h1 {
  font-size: 100px;
  margin: 0;
}

.not-found p {
  font-size: 24px;
}

/* svg{
  color: white !important;
} */

.demo .card-body{
  padding: 20px 15px 20px;
  background-color: #fff;
    border-radius: 15px;
    border: 1px solid #eceef0;
    /* box-shadow: 3px 3px 8px rgb(0 0 0 / 10%); */
}
.demo .Card{
  border-radius: 15px;
}
.bg-box {
  /* background: #358ef93d; */
  width: 100%;
  float: left;
  border-radius: 5px;
  /* padding: 10px 15px; */
  margin-bottom: 10px;
}

.bg-box .date{
  width: 100%;
  float: left;
  margin-bottom:10px ;
}

.demo .card{
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 15px;
}

.demo .card:hover {
  box-shadow: 3px 3px 8px rgb(0 0 0 / 10%);
}

.demo .card-body .card-link{
  border: 1px solid #358ef9;
  color: #358ef9 !important;
  border-radius: 20px;
  padding: 5px 20px;
}

.bg-box .date span{
  background: #358ef9;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    margin-right:5px ;
}

.bg-box .h5{
  font-size: 32px;
  font-weight: 300;
  text-transform: capitalize;
  width: 100%;
  margin: 10px 0px 20px;
  float: left;
}

.demo .card:hover .card-body {
  background-color: #358ef9;
}
.demo .card:hover .date span {
  background: #fff;
  color: #358ef9;
}
.demo .card:hover .h5 {
  color: #fff;
}
.demo .card:hover .card-link {
  background: #fff;
}




/* google sign logo */
.qJTHM{
  display: grid !important;
}



/* add by rupinder */
.Copy_icon{
  color: #000 !important;
}
/* .cpy_add_icon{
  justify-content: space-around !important;
  display: flex;
} */
button.email-btn1.plus {
  margin-left: 7px;
}

.main_tb_h{
  margin-left: 4%;
  margin-top: 15px;
}
/* event type */
.main_tb{
  width: 100%;
 
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 8px;
  background-color:#ffffff !important;
}
.table_rw{
  position: relative;
  display: grid;
}
.table_btn{
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    font: inherit;
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: pointer;
}
.table_btn_dv{
  flex-shrink: 0;
  width: 128px;
  text-align: center;
}
.table_rw_d{
  border: 1px solid #cccccc;
  border-radius: 8px;

}
.table_rw_d:hover{
  border: 1px solid #198ef9;

}
.table_btn_dv_main{
  flex-grow: 1;
  padding: 27px 0;
  text-align: left;
}
.btn_dv_hed{
  font-size: 20px;
  line-height: 20px;
}
.btn_dv_hed2
{
  margin: 8px 0;
  font-size: 16px;
  line-height: 1.4;
}
.btn_dv_p{
  color: var(--text-color-level2, rgba(26, 26, 26, 0.61));
  font-size: 14px;
  line-height: 1.4;
}

.btn_icon_dv{
  flex-shrink: 0;
  width: 64px;
  text-align: center;
}

.btn_icon_spn{
  width: 20px;
  height: 20px;
}
.meet_main {
  margin-top: 48px;
}

.meet_main_p {
  font-weight: 700;
}

.meet_main_dv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 24px;
}

.meet_btn{
  display: flex;
  flex: 1 1 0px;
  flex-basis: 0;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
  padding: 12px 16px;
  border: 1px solid var(--color-grey-3, #CCCCCC);
  border-radius: 8px;
  background-color: var(--container-background-color, #ffffff);
  text-align: left;
}
.meet_main_p{
  font-size: 16px;
  font-weight: 700;
}
.meet_btn_p
{
  font-size: 16px;
  font-weight: 400;
}
.meet_btn_txt {
  margin-top: 12px;
  color: var(--primary-color, rgb(0, 105, 255));
  font-size: 16px;
  line-height: 1.4;
}
.main_h_d{
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  margin-left: 12%;
}

.dv_hed{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 4%;
}

.head_spn{
  width: 16px;
  height: 16px;
  stroke-width: .8;
  display: inline-block;
    vertical-align: top;
    flex-shrink: 0;
}

.Main_hed_dv{
  display: flex;
  gap: 8px;
  align-items: center;
}
.Main_head {
  margin: 0;
  font-weight: 700;
  line-height: 1.4;
  font-size: 28px;
}

.spn_atri {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 8px 12px 8px 4px;
  color: #198ef9 !important;
  font-weight: 700;
  white-space: nowrap;
}
.table_btn:focus-visible {
  outline-width: 2px;
  outline-style: solid;
  outline-color: #198ef9;
  outline-offset: 2px;
}


/* Login with google by roop */
/* .login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
 
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  
  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  &:active {
    background-color: #eeeeee;
  }
  
  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
} */





.google-button {
  all: unset;

  margin: auto;
  width: fit-content;
  /* height: 40px; */
  background-color:  #4880FF;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: all 300ms ease-in-out;
  display: flex;
  justify-content: space-between;
  padding: 1px 80px 1px 20px;
    border-radius: 7px;
margin-top: 10px;
box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
  .google-icon-wrapper {
    margin: 1px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-around;
   
  }

  .google-button-text {
    display: flex;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
    margin-left: 1em;
    margin-right: 1em;
    align-items: center;
    margin-bottom: 0px !important;
    font-family: "Poppins", sans-serif;
  }

  &:hover {
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    cursor: pointer;
  }
  &:active {
    background: #3367d6;
  }
}
/* .dlte_btn {
  background: #358ef9;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  margin-right: 5px;
} */
/* .dlt_img{
  width: 30px;
} */
.demo .card:hover .dlte_img svg {
 
  fill: rgb(255, 255, 255); 
 

}
/* dashboard by roop */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');


body{
  font-family: 'Nunito', sans-serif;
  color:#676767;
  background-color: #1e1e1e;
}
.fixed.inset-y-0.left-0.bg-card.w-full.sm\:w-20.xl\:w-60.sm\:flex.flex-col.z-10.hidden.disp {
  display: none;
}
.bg-card {
  background-color: #e8e8e8;
}
.bg-sidebar-card-top{
  background-color: #353535;
}
.sidebar-separator-top{
  border-bottom: 1px solid #2e2e2e;
}
.sidebar-separator-bottom{
  border-top: 1px solid #2e2e2e;
}
.text-premium-yellow{
  color: #f7b91c;
}
.icon-background{
  background: #2d2d2d;
}

.tooltip-head{
background: #1d1d1d;
}
.tooltip-body{
  background:#252525 ;
}

.search-icon{
  top: 50%;
  transform: translate(0, -50%);
}

.card-stack-border{
  border-bottom: 2px solid #696969;
}
.bg-details {
  background-color: #BDBDBE;
}

.add-component-head{
  background-color: #5f5f5f;
  background-image: url("https://www.transparenttextures.com/patterns/carbon-fibre.png");
}
.flex_ {
  display: flex;
  flex-direction: column;
}
.sidebar-item-selected{
  color: white;
  border-right: 2px solid white;
}
.sidebar-item{
  border-right: 2px solid transparent;
}
.sidebar-item:hover {
 color:#a1a0a0;
}
.css-196tt05{
  /* padding: 50px 50px !important; */
  margin-top: 30px !important;
  margin-left: 40px !important;
  /* margin: 30px; */
  width: 90% !important;
}
.MuiGrid-root.MuiGrid-container.css-dasaed-MuiGrid-root {
  padding: 50px 50px !important;
  margin-top: 30px !important;
  margin-left: 40px;
  /* margin: 30px; */
  width: 90% !important;
}
.css-14oqbfp {
  padding: 12px 24px 10px !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiCard-root.css-g04yqm 
{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;

}
.error_{
  margin: 20px 20px;
}
  /* box-shadow: rgb(0 0 0 / 13%) 0px 1px 4px; */
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
/* .MuiBox-root.css-ckcvyb {

  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
} */
/* Add this to your CSS file */
@keyframes wave {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(10deg); }
  50% { transform: rotate(-10deg); }
  75% { transform: rotate(10deg); }
  100% { transform: rotate(0deg); }
}

.wave-hand {
  display: inline-block;
  animation: wave 1s ease-in-out; /* Duration and type of animation */
  animation-iteration-count: 1; /* Play animation only once */
}






