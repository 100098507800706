/* style.css or your Tailwind-based custom CSS */
.rbc-calendar {
    @apply text-gray-800; /* Text color */
  }
  
  .rbc-toolbar button {
    @apply bg-blue-500 text-white py-1 px-4 rounded-md shadow hover:bg-blue-600; /* Custom button styles */
  }
  
  .rbc-month-view {
    @apply border border-gray-200; /* Border for month view */
  }
  